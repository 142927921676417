import React from "react";
import { PageProps, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { AllPersonsQuery } from "../../graphql-types";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";

const BebeletesGridStyles = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const BebeleteStyles = styled.div`
  a {
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 20rem;
  }
  h2 {
    transform: rotate(-2deg);
    text-align: center;
    font-size: 4rem;
    margin-bottom: -2rem;
    position: relative;
    z-index: 2;
  }
  .description {
    background: var(--yellow);
    padding: 1rem;
    margin: 2rem;
    margin-top: -2rem;
    z-index: 2;
    position: relative;
    transform: rotate(1deg);
    text-align: center;
  }
`;

interface IBebeletesPage extends PageProps {
  data: AllPersonsQuery;
  pageContext: {
    skip: number;
    currentPage: number;
    pageSize: number;
  };
}

export default function BebeletesPage({ data, pageContext }: IBebeletesPage) {
  const bebeletes = data.bebeletes.nodes;

  return (
    <>
      <SEO title={`Bebeletes - Pagina ${pageContext.currentPage || 1}`} />
      <Pagination
        pageSize={pageContext.pageSize}
        totalCount={data.bebeletes.totalCount}
        currentPage={pageContext.currentPage || 1}
        skip={pageContext.skip}
        base="/bebeletes"
      />
      <BebeletesGridStyles>
        {bebeletes.map((person) => (
          <BebeleteStyles key={person.id}>
            <Link to={`/bebelete/${person.slug.current}`}>
              <h2>
                <span className="mark">{person.name}</span>
              </h2>
            </Link>
            <Img fluid={person.image.asset.fluid} alt={person.name} />
            <p className="description">{person.description}</p>
          </BebeleteStyles>
        ))}
      </BebeletesGridStyles>
    </>
  );
}

export const query = graphql`
  query AllPersons($skip: Int = 0, $pageSize: Int = 4) {
    bebeletes: allSanityPerson(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        name
        id
        slug {
          current
        }
        description
        image {
          asset {
            fluid(maxHeight: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
